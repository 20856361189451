<template>
  <v-card
    tile
    :loading="loading"
    :disabled="
      medical_revision
        ? data
          ? data.accident === null && data && data.initial === null
            ? !login.permissions.rs_medical_revisions.create
            : !login.permissions.rs_medical_revisions.update
          : true
        : loading
    "
  >
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{
              name: `${page_route}${id ? '.detalle' : ''}`,
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${data.folio ? `${data.folio} | ` : ''}${$route.meta.title}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_submit" lazy-validation>
        <v-row>
          <v-col cols="12" v-if="login.role_id != 7">
            <v-card tile :disabled="medical_revision">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'GENERAL'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="6" v-if="login.role_id === 1">
                    <v-autocomplete
                      label="Usuario*"
                      v-model="data.user_id"
                      :items="users"
                      item-value="id"
                      :item-text="(item) => item.email"
                      :loading="users_loading"
                      v-on:change="userSelected()"
                      :disabled="data.insured_id != null"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6" v-if="data.user_id">
                    <v-autocomplete
                      label="Aseguradora*"
                      v-model="data.insurance_id"
                      :items="insurances"
                      item-value="insurance_id"
                      :item-text="(item) => item.name"
                      :loading="insurances_loading"
                      :disabled="data.insured_id != null"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="data.insurance_id && !data.insured_id && login.role_id != 7"
          >
            <v-card tile :loading="insured_loading" :disabled="insured_loading">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO | BÚSQUEDA'" />
                  <small
                    class="text-caption"
                    v-text="' | Pulsa enter sobre un campo para buscar'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="info"
                        icon
                        @click.prevent="insuredSearchValidation"
                      >
                        <v-icon v-text="'mdi-account-search'" />
                      </v-btn>
                    </template>
                    <span v-text="'Buscar asegurado'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="ID SM / Póliza"
                      v-model="insured_search.enrollment"
                      type="text"
                      v-on:keyup.enter="insuredSearchValidation"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Nombre"
                      v-model="insured_search.name"
                      type="text"
                      v-on:keyup.enter="insuredSearchValidation"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="A. Paterno"
                      v-model="insured_search.first_surname"
                      type="text"
                      v-on:keyup.enter="insuredSearchValidation"
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      color="info"
                      small
                      @click.prevent="insuredSearchValidation"
                    >
                      <v-icon left v-text="'mdi-account-search'" />
                      BUSCAR ASEGURADO
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-if="!insured_search_validation">
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          No se puede realizar búsqueda, utiliza el campo de ID
                          SM / Póliza y/o el Nombre (3 caracteres mín.) más A.
                          paterno (3 caracteres mín.)
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                  <v-col cols="12" v-if="insured_search_fail && !insured_form">
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          Asegurado no encontrado en sistema, ¿Desea registrar
                          manualmente la información?
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                            small
                            v-text="'Aceptar'"
                            @click.prevent="insuredForm"
                          />
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured_id">
            <v-card tile :disabled="medical_revision">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'ASEGURADO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip
                    v-if="
                      !medical_revision &&
                      login.role_id != 7 &&
                      data.rs_type_id != 4
                    "
                    left
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        color="warning"
                        icon
                        @click.prevent="insuredRemove"
                      >
                        <v-icon v-text="'mdi-account-reactivate'" />
                      </v-btn>
                    </template>
                    <span v-text="'VOLVER A BÚSQUEDA DE ASEGURADO'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col v-if="data.rs_type_id != 4" cols="12" xs="12" md="3">
                    <v-text-field
                      label="ID SM / Póliza"
                      v-model="data.enrollment"
                      maxlength="10"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.required"
                      maxlength="50"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="A. Paterno*"
                      v-model="data.first_surname"
                      :rules="rules.required"
                      maxlength="25"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="A. Materno"
                      v-model="data.second_surname"
                      maxlength="25"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.rs_type_id != 4" cols="12" xs="12" md="6">
                    <v-text-field
                      label="Nombre de oficina"
                      v-model="data.office_address"
                      maxlength="100"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.rs_type_id != 4" cols="12" xs="12" md="6">
                    <v-text-field
                      :label="`Contratante${
                        data.rs_type_id == 1 ||
                        data.rs_type_id == 5 ||
                        data.rs_type_id == 6
                          ? '*'
                          : ''
                      }`"
                      v-model="data.contractor"
                      :rules="
                        data.rs_type_id == 1 ||
                        data.rs_type_id == 5 ||
                        data.rs_type_id == 6
                          ? rules.required
                          : []
                      "
                      maxlength="255"
                      type="text"
                      :disabled="!insured_form"
                      dense
                    />
                  </v-col>
                  <v-col v-if="data.insured" cols="12" xs="12" md="4">
                    <ViewData
                      label="F. nacimiento"
                      :value="
                        data.insured.birthday ? data.insured.birthday : '-'
                      "
                    />
                  </v-col>
                  <v-col v-if="data.insured" cols="12" xs="12" md="4">
                    <ViewData
                      label="Vigencia"
                      :value="
                        data.insured.validity ? data.insured.validity : '-'
                      "
                    />
                  </v-col>
                  <v-col v-if="data.insured" cols="12" xs="12" md="4">
                    <ViewData
                      label="Antigüedad"
                      :value="
                        data.insured.antiquity ? data.insured.antiquity : '-'
                      "
                    />
                  </v-col>
                  <v-col cols="12" v-if="data.expired" class="text-center">
                    <v-alert type="error" dense>
                      Vigencia vencida con fecha del {{ data.validity }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured_id">
            <v-card tile :disabled="medical_revision">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SOLICITUD'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Tipo*"
                      v-model="data.rs_type_id"
                      :items="rs_types"
                      item-value="id"
                      :item-text="(v) => v.type"
                      :loading="rs_types_loading"
                      dense
                      :disabled="data.services.length > 0"
                      @change="handleType"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3" v-if="login.role_id != 7">
                    <v-text-field
                      label="Correo electrónico"
                      v-model="data.email"
                      :rules="rules.emailNoRequired"
                      maxlength="80"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Teléfono"
                      v-model="data.phone"
                      maxlength="10"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="3"
                    v-if="
                      (data.rs_type_id == 1 ||
                        data.rs_type_id == 5 ||
                        data.rs_type_id == 6 ||
                        data.rs_type_id == 4) &&
                      login.role_id != 7
                    "
                  >
                    <v-text-field
                      label="Folio de envío"
                      v-model="data.send_folio"
                      maxlength="15"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col
                    cols="12"
                    xs="12"
                    md="4"
                    v-if="
                      (data.rs_type_id == 1 ||
                        data.rs_type_id == 5 ||
                        data.rs_type_id == 6 ||
                        data.rs_type_id == 4) &&
                      (data.insured_id === -1 ||
                        data.insured.contractor.telemedicine)
                    "
                  >
                    <v-switch
                      class="mt-0"
                      :label="'Consulta médica telefónica'"
                      v-model="data.phone_medical"
                    />
                  </v-col>
                </v-row>

                <v-row
                  dense
                  v-if="
                    (data.rs_type_id == 1 ||
                      data.rs_type_id == 5 ||
                      data.rs_type_id == 6 ||
                      data.rs_type_id == 4) &&
                    (data.insured_id === -1 ||
                      data.insured.contractor.telemedicine) &&
                    data.phone_medical
                  "
                >
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado*"
                      v-model="data.phone_medical_entity_id"
                      :rules="rules.required"
                      :items="entities"
                      item-value="id"
                      :item-text="(v) => v.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities(true)"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio*"
                      v-model="data.phone_medical_municipality_id"
                      :rules="rules.required"
                      :items="phone_medical_municipalities"
                      item-value="id"
                      :item-text="(v) => v.municipality"
                      :loading="phone_medical_municipalities_loading"
                      dense
                      v-on:change="
                        service_search.municipality_id =
                          data.phone_medical_municipality_id
                      "
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <DatePicker
                      label="Fecha*"
                      :model.sync="data.phone_medical_date"
                      :rules="rules.required"
                      clean
                      after_date
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      v-model="data.phone_medical_time"
                      label="Hora*"
                      :rules="rules.required"
                      type="time"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="data.insured && !data.insured.contractor.telemedicine"
                  >
                    <v-alert type="warning" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          El contratante no cuenta con el servicio de Consulta
                          médica telefónica
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-textarea
                      rows="3"
                      label="Detalle de la solicitud"
                      v-model="data.detail"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured_id && medical_revision">
            <v-card class="mx-auto">
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="'DIAGNOSTICO DEFINITIVO (ICD)'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="11" class="mt-4">
                    <v-autocomplete
                      label="Descripción"
                      v-model="icd"
                      :items="icds"
                      :item-text="(item) => `${item.code} | ${item.icd}`"
                      :loading="icds_loading"
                      return-object
                      dense
                    />
                  </v-col>
                  <v-col cols="1" class="mt-4">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          dark
                          small
                          block
                          color="orange darken-2"
                          @click="icdAdd"
                        >
                          <v-icon v-text="'mdi-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Agregar'" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="mb-5">
                    <hr />
                  </v-col>
                  <v-col cols="12">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th v-text="'#'" />
                            <th v-text="'Código'" />
                            <th v-text="'Descripción'" />
                            <th v-text="''" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(v, i) in data.icds"
                            :key="i"
                            v-if="v.active"
                          >
                            <td v-text="`${i + 1}`" />
                            <td v-text="v.code" />
                            <td v-text="v.icd" />
                            <td>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    icon
                                    x-small
                                    color="error"
                                    @click="icdRemove(i)"
                                  >
                                    <v-icon medium v-text="'mdi-close'" />
                                  </v-btn>
                                </template>
                                <span v-text="`Eliminar ICD (${i + 1})`" />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>

                  <v-col v-if="sinister_rep" cols="12">
                    <v-row dense>
                      <v-col cols="12" />
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="12">
                        <h4 v-text="'SINIESTRALIDAD'" />
                      </v-col>
                    </v-row>
                    <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
                      <v-col cols="12">
                        <span
                          class="text-caption"
                          v-text="icd.code + ' | ' + icd.icd"
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-text="'#'" />
                                <th v-text="'Tipo'" />
                                <th v-text="'Proveedor'" />
                                <th v-text="'T. Prov. | Espec.'" />
                                <th v-text="'Folio'" />
                                <th v-text="'Fecha'" />
                                <th v-text="'Padecimiento'" />
                                <th v-text="'Siniestro'" />
                                <th v-text="'Núm. Siniestro'" />
                                <th v-text="'Deducible'" />
                                <th v-text="'Coaseguro %'" />
                                <th v-text="'Facturado'" />
                                <th v-text="'Monto'" />
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, i) in icd.items" :key="i">
                                <td v-text="`${i + 1}`" />
                                <td v-text="item.type" />
                                <td v-text="item.provider" />
                                <td v-text="item.provider_type" />
                                <td v-text="item.folio" />
                                <td v-text="item.date" />
                                <td
                                  v-text="
                                    item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'
                                  "
                                />
                                <td
                                  v-text="
                                    item.initial ? 'INICIAL' : 'COMPLEMENTARIO'
                                  "
                                />
                                <td v-text="item.sinister_number" />
                                <td
                                  v-text="numberFormat(item.deducible_amount)"
                                />
                                <td v-text="item.coinsurance" />
                                <td>
                                  <v-icon
                                    small
                                    :color="item.bill ? 'success' : ''"
                                  >
                                    mdi-{{ item.bill ? "check" : "close" }}
                                  </v-icon>
                                </td>
                                <td v-text="numberFormat(item.amount)" />
                                <td>
                                  <v-tooltip v-if="item.comment" left>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        v-on="on"
                                        fab
                                        dark
                                        x-small
                                        class="ml-1"
                                        color="info"
                                        @click="commentDialog(item)"
                                      >
                                        <v-icon> mdi-message-draw </v-icon>
                                      </v-btn>
                                    </template>
                                    <span v-text="'Seg. médico comentario'" />
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr>
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td />
                                <td>
                                  <b v-text="numberFormat(icd.amount)" />
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured_id && medical_revision">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SEGUIMIENTO MÉDICO'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        icon
                        fab
                        x-small
                        color="primary"
                        @click.prevent="insuredHistoryDialog"
                      >
                        <v-icon> mdi-file-sign </v-icon>
                      </v-btn>
                    </template>
                    <span v-text="'Ver historial médico'" />
                  </v-tooltip>
                </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="4">
                    <v-radio-group
                      class="mt-0"
                      v-model="data.accident"
                      :rules="rules.requiredNotNull"
                      row
                    >
                      <v-radio label="Accidente" :value="true" />
                      <v-radio label="Enfermedad" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-radio-group
                      class="mt-0"
                      v-model="data.initial"
                      :rules="rules.requiredNotNull"
                      row
                    >
                      <v-radio label="Inicial" :value="true" />
                      <v-radio label="Complemento" :value="false" />
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-switch
                      class="mt-0"
                      :label="'Prexistente / Riesgo'"
                      v-model="data.preexisting"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      rows="7"
                      label="Comentario"
                      v-model="data.comment"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" v-if="data.insured_id">
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'DOCUMENTOS'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" v-for="(v, i) in data.documents" :key="i">
                    <v-row v-if="v.active">
                      <v-col cols="12" xs="12" md="5">
                        <v-text-field
                          label="Descripción"
                          v-model="v.description"
                          maxlength="100"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-file-input
                          label="Archivo digital*"
                          v-model="v.file"
                          accept="image/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                          :rules="id ? [] : rules.required"
                          show-size
                          dense
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="1" class="text-right">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="error"
                              @click="fileRemove(i)"
                            >
                              <v-icon medium v-text="'mdi-file-remove'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar documento (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider class="pb-1" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip right>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          fab
                          x-small
                          color="warning"
                          :loading="loading"
                          @click="fileAdd"
                        >
                          <v-icon v-text="'mdi-file-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Agregar documento'" />
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" v-if="medical_revision">
            <RsServices
              :data="data.rs_services"
              :title="`SERVICIOS SIN SEGUIMIENTO (${data.rs_services.length})`"
            />
          </v-col> -->
          <v-col
            cols="12"
            v-if="
              data.insured_id &&
              ((id === null && !data.phone_medical) || id) &&
              (data.rs_type_id == 1 ||
                data.rs_type_id == 5 ||
                data.rs_type_id == 6 ||
                data.rs_type_id == 4)
            "
          >
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span
                    class="text-caption"
                    v-text="'SERVICIOS SELECCIONADOS'"
                  />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <div v-if="data.services.length > 0">
                  <v-row v-for="(v, i) in data.services" :key="i">
                    <v-col
                      cols="12"
                      v-if="v.active && v.doctor && v.consulting_room"
                    >
                      <v-card tile>
                        <v-toolbar elevation="1" dense>
                          <v-toolbar-title>
                            <span class="text-caption">
                              {{
                                `${i + 1}. ${
                                  data.rs_type_id == 4 ||
                                  data.rs_type_id == 5 ||
                                  data.rs_type_id == 6
                                    ? "MÉDICO"
                                    : "CONSULTA"
                                } ${v.medical_revision ? "(SEG. MÉDICO)" : ""}`
                              }}
                              <v-icon
                                v-if="v.auto_letter_generated"
                                small
                                right
                                color="success"
                              >
                                mdi-window-shutter-auto
                              </v-icon>
                            </span>
                          </v-toolbar-title>
                          <v-spacer />
                          <v-toolbar-items>
                            <v-tooltip v-if="!v.letter_generated" left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="error"
                                  icon
                                  @click.prevent="serviceRemove(i)"
                                >
                                  <v-icon v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span
                                v-text="
                                  `ELIMINAR ${
                                    data.rs_type_id == 4 ||
                                    data.rs_type_id == 5 ||
                                    data.rs_type_id == 6
                                      ? 'MÉDICO'
                                      : 'CONSULTA'
                                  } (${i + 1})`
                                "
                              />
                            </v-tooltip>
                            <v-alert v-else type="success" dense>
                              <v-row align="center">
                                <v-col class="text-center">
                                  CARTA GENERADA
                                </v-col>
                              </v-row>
                            </v-alert>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-spacer />
                        <v-card-text>
                          <v-row dense>
                            <v-col
                              cols="12"
                              v-if="
                                login.role_id != 7 &&
                                v.confirm_consultation &&
                                data.rs_type_id != 4 &&
                                data.rs_type_id != 5
                              "
                            >
                              <v-alert type="warning" dense>
                                <v-row align="center">
                                  <v-col class="text-center">
                                    Se requiere confirmación de consulta
                                  </v-col>
                                </v-row>
                              </v-alert>
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                              <ViewData
                                label="Médico"
                                :value="`${v.doctor.name} ${v.doctor.first_surname} ${v.doctor.second_surname}`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                v-if="login.role_id != 7 && login.role_id != 8"
                                label="Contacto"
                                :value="`${
                                  v.doctor.phone
                                    ? `TEL. ${v.doctor.phone} / `
                                    : ''
                                }${
                                  v.doctor.cell_phone
                                    ? `CEL. ${v.doctor.cell_phone} / `
                                    : ''
                                }${v.doctor.email ? v.doctor.email : ''}`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                v-if="
                                  login.role_id != 7 &&
                                  login.role_id != 8 &&
                                  data.rs_type_id != 4
                                "
                                label="Monto"
                                :value="numberFormat(v.benefits[0].amount)"
                              />
                              <v-text-field
                                v-if="
                                  login.role_id != 7 &&
                                  login.role_id != 8 &&
                                  data.rs_type_id == 4
                                "
                                label="Monto"
                                v-model="v.benefits[0].amount"
                                type="number"
                                dense
                                :rules="rules.required"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Dirección"
                                :value="`${
                                  v.consulting_room.street
                                    ? v.consulting_room.street
                                    : ''
                                }${
                                  v.consulting_room.external_number
                                    ? ` ${v.consulting_room.external_number}`
                                    : ''
                                }${
                                  v.consulting_room.internal_number
                                    ? `, INT. ${v.consulting_room.internal_number}`
                                    : ''
                                }${
                                  v.consulting_room.suburb
                                    ? `, COL. ${v.consulting_room.suburb}`
                                    : ''
                                }`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Municipio"
                                :value="v.consulting_room.municipality_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Estado"
                                :value="v.consulting_room.entity_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Código postal"
                                :value="v.consulting_room.zip"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                :label="'Especialidad'"
                                :value="
                                  v.doctor_specialty
                                    ? v.doctor_specialty.name
                                    : '-'
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Cédula"
                                :value="
                                  v.doctor_specialty
                                    ? v.doctor_specialty.license
                                    : '-'
                                "
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" v-if="v.active && v.provider">
                      <v-card tile>
                        <v-toolbar elevation="1" dense>
                          <v-toolbar-title>
                            <span class="text-caption">
                              {{
                                `${i + 1}. PROVEEDOR ${
                                  v.medical_revision ? "(SEG. MÉDICO)" : ""
                                }`
                              }}
                              <v-icon
                                v-if="v.auto_letter_generated"
                                small
                                right
                                color="success"
                              >
                                mdi-window-shutter-auto
                              </v-icon>
                            </span>
                          </v-toolbar-title>
                          <v-spacer />
                          <v-toolbar-items>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="error"
                                  icon
                                  @click.prevent="serviceRemove(i)"
                                >
                                  <v-icon v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span v-text="`ELIMINAR PROVEEDOR (${i + 1})`" />
                            </v-tooltip>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-spacer />
                        <v-card-text>
                          <v-row dense>
                            <v-col
                              cols="12"
                              v-if="v.benefits_amount > v.assured_amount"
                            >
                              <v-alert type="warning" class="text-center" dense>
                                Se ha excedido el tope máximo asegurado
                                {{ numberFormat(v.assured_amount) }}
                              </v-alert>
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Tipo de proveedor"
                                :value="v.provider.provider_type.provider_type"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Nombre"
                                :value="v.provider.name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="6">
                              <ViewData
                                label="Nombre de comercial"
                                :value="v.provider.trade_name"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Dirección"
                                :value="`${
                                  v.provider.street ? v.provider.street : ''
                                }${
                                  v.provider.external_number
                                    ? ` ${v.provider.external_number}`
                                    : ''
                                }${
                                  v.provider.internal_number
                                    ? `, INT. ${v.provider.internal_number}`
                                    : ''
                                }${
                                  v.provider.suburb
                                    ? `, COL. ${v.provider.suburb}`
                                    : ''
                                }`"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Municipio"
                                :value="v.provider.municipality"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Estado"
                                :value="v.provider.entity"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Código postal"
                                :value="v.provider.zip"
                              />
                            </v-col>
                            <v-col v-if="login.role_id != 7" cols="12">
                              <span
                                class="text-caption font-weight-bold"
                                v-text="'Prestaciones'"
                              />
                            </v-col>

                            <v-col v-if="login.role_id != 7" cols="12">
                              <v-row dense>
                                <v-col cols="12" v-if="v.benefits.length > 0">
                                  <v-simple-table dense>
                                    <template v-slot:default>
                                      <thead>
                                        <tr>
                                          <th v-text="'#'" />
                                          <th v-text="'Código'" />
                                          <th v-text="'Descripción'" />
                                          <th v-text="'Cobertura'" />
                                          <th v-text="'Monto'" />
                                          <th v-text="''" />
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(vb, k) in v.benefits"
                                          :key="k"
                                          v-if="vb.active"
                                        >
                                          <td v-text="`${k + 1}`" />
                                          <td v-text="vb.code" />
                                          <td v-text="vb.description" />
                                          <td
                                            :class="
                                              vb.revision
                                                ? 'orange--text text--darken-3'
                                                : ''
                                            "
                                            v-text="
                                              vb.coverage_type
                                                ? vb.coverage_type
                                                : 'N/A'
                                            "
                                          />
                                          <td
                                            v-text="numberFormat(vb.amount)"
                                          />
                                          <td>
                                            <v-tooltip left>
                                              <template
                                                v-slot:activator="{ on }"
                                              >
                                                <v-btn
                                                  v-on="on"
                                                  text
                                                  icon
                                                  x-small
                                                  color="error"
                                                  @click="benefitRemove(k, i)"
                                                >
                                                  <v-icon
                                                    medium
                                                    v-text="'mdi-close'"
                                                  />
                                                </v-btn>
                                              </template>
                                              <span
                                                v-text="
                                                  `ELIMINAR PRESTACIÓN (${
                                                    k + 1
                                                  })`
                                                "
                                              />
                                            </v-tooltip>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </template>
                                  </v-simple-table>

                                  <div class="text-center">
                                    <small v-text="'Monto Total'" />
                                    <p
                                      class="text-h6"
                                      v-text="numberFormat(v.benefits_amount)"
                                    />
                                  </div>
                                </v-col>
                                <v-col cols="12" v-else>
                                  <p class="text-caption">
                                    Ningúna prestación ha sido agregada
                                  </p>
                                </v-col>

                                <v-col cols="12" xs="12" md="6">
                                  <v-text-field
                                    label="Código"
                                    v-model="v.benefit_search.code"
                                    type="text"
                                    dense
                                    :disabled="benefits_dialog_loading"
                                    v-on:keyup.enter="benefitSearch(i)"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="6">
                                  <v-text-field
                                    label="Descripción"
                                    v-model="v.benefit_search.description"
                                    type="text"
                                    dense
                                    :disabled="benefits_dialog_loading"
                                    v-on:keyup.enter="benefitSearch(i)"
                                  />
                                </v-col>
                                <v-col cols="12" xs="12" md="12">
                                  <v-btn
                                    block
                                    color="teal darken-1"
                                    dark
                                    small
                                    @click.prevent="benefitSearch(i)"
                                    :loading="benefits_dialog_loading"
                                  >
                                    <v-icon
                                      left
                                      v-text="'mdi-database-search'"
                                    />
                                    BUSCAR PRESTACIÓN
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col
                      cols="12"
                      v-if="
                        v.active &&
                        v.agreement &&
                        !v.provider &&
                        !v.doctor &&
                        !v.consulting_room
                      "
                    >
                      <v-card tile>
                        <v-toolbar elevation="1" dense>
                          <v-toolbar-title>
                            <span
                              class="text-caption"
                              v-text="
                                `${i + 1}. CONVENIO (${
                                  v.rs_service_type_id === 1
                                    ? 'CONSULTA'
                                    : 'PROVEEDOR'
                                }) ${v.medical_revision ? '(SEG. MÉDICO)' : ''}`
                              "
                            />
                          </v-toolbar-title>
                          <v-spacer />
                          <v-toolbar-items>
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  color="error"
                                  icon
                                  @click.prevent="serviceRemove(i)"
                                >
                                  <v-icon v-text="'mdi-close'" />
                                </v-btn>
                              </template>
                              <span v-text="`ELIMINAR CONVENIO (${i + 1})`" />
                            </v-tooltip>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-spacer />
                        <v-card-text>
                          <v-row dense>
                            <v-col v-if="login.role_id != 7" cols="12">
                              <v-alert type="warning" dense>
                                <v-row align="center">
                                  <v-col class="text-center">
                                    No se encontró
                                    {{
                                      v.rs_service_type_id === 1
                                        ? "médico"
                                        : "proveedor"
                                    }}
                                    para
                                    {{
                                      v.rs_service_type_id === 1
                                        ? "consulta"
                                        : "servicio"
                                    }}
                                  </v-col>
                                </v-row>
                              </v-alert>
                            </v-col>
                            <v-col
                              cols="12"
                              xs="12"
                              md="3"
                              v-if="v.rs_service_type_id === 1"
                            >
                              <ViewData
                                label="Tipo de especialidad "
                                :value="v.specialty_type.specialty_type"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              xs="12"
                              md="3"
                              v-if="v.rs_service_type_id === 2"
                            >
                              <ViewData
                                label="Tipo de proveedor"
                                :value="v.provider_type.provider_type"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Municipio"
                                :value="
                                  v.municipality_id === null
                                    ? 'TODOS'
                                    : v.municipality.municipality
                                "
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData
                                label="Estado"
                                :value="v.entity ? v.entity.entity : 'TODOS'"
                              />
                            </v-col>
                            <v-col cols="12" xs="12" md="3">
                              <ViewData label="Código postal" :value="v.zip" />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>No selecciono ningún servicio.</div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            v-if="
              data.insured_id &&
              ((id === null && !data.phone_medical) || id) &&
              (data.rs_type_id == 1 ||
                data.rs_type_id == 5 ||
                data.rs_type_id == 6 ||
                data.rs_type_id == 4)
            "
          >
            <v-card tile>
              <v-toolbar elevation="1" dense>
                <v-toolbar-title>
                  <span class="text-caption" v-text="'SERVICIO | BÚSQUEDA'" />
                </v-toolbar-title>
                <v-spacer />
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <v-spacer />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="6">
                    <v-select
                      label="Búsqueda de"
                      v-model="service_search.rs_service_type_id"
                      :items="rs_service_types"
                      item-value="id"
                      :item-text="(i) => i.type"
                      v-on:change="serviceSearchDefTypes()"
                      dense
                      item-disabled="item_disabled"
                    />
                  </v-col>
                  <v-col
                    v-if="service_search.rs_service_type_id === null"
                    cols="12"
                    xs="12"
                    md="6"
                  />
                  <v-col
                    cols="12"
                    xs="12"
                    md="6"
                    v-if="service_search.rs_service_type_id == 1"
                  >
                    <v-autocomplete
                      label="Tipo de especialidad"
                      v-model="service_search.specialty_type_id"
                      :items="specialty_types"
                      item-value="id"
                      :item-text="(i) => i.specialty_type"
                      :loading="specialty_types_loading"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="6"
                    v-if="service_search.rs_service_type_id === 2"
                  >
                    <v-autocomplete
                      label="Tipo de proveedor"
                      v-model="service_search.provider_type_id"
                      :items="provider_types"
                      item-value="id"
                      :item-text="(i) => i.provider_type"
                      :loading="provider_types_loading"
                      dense
                      item-disabled="item_disabled"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Estado"
                      v-model="service_search.entity_id"
                      :items="entities"
                      item-value="id"
                      :item-text="(i) => i.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities(false)"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-autocomplete
                      label="Municipio"
                      v-model="service_search.municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(i) => i.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="C.P."
                      v-model="service_search.zip"
                      maxlength="5"
                      type="text"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    v-if="
                      service_search.rs_service_type_id &&
                      (service_search.specialty_type_id ||
                        service_search.provider_type_id) &&
                      ((data.rs_type_id >= 4 && data.services.length == 0) ||
                        data.rs_type_id < 4)
                    "
                  >
                    <v-btn
                      block
                      color="info"
                      small
                      @click.prevent="serviceSearch"
                    >
                      <v-icon left v-text="'mdi-database-search'" />
                      BUSCAR
                      {{
                        service_search.rs_service_type_id == 1
                          ? data.rs_type_id == 4 ||
                            data.rs_type_id == 5 ||
                            data.rs_type_id == 6
                            ? "MÉDICO"
                            : "CONSULTA"
                          : "PROVEEDOR"
                      }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-alert v-if="data.rs_type_id < 4" type="info" dense>
                      <v-row align="center">
                        <v-col class="text-center">
                          Se requiere mínimo los campos de "Búsqueda de", "Tipo
                          de proveedor o especialidad" para poder realizar una
                          búsqueda
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            xs="12"
            class="text-right"
            v-if="
              !data.expired &&
              ((data.insured_id && data.rs_type_id > 1) ||
                (data.insured_id && data.phone_medical) ||
                (data.insured_id && data.services.length > 0) ||
                medical_revision)
            "
          >
            <BtnCircle
              :tLabel="
                id
                  ? medical_revision
                    ? 'Seguimiento médico'
                    : 'Editar'
                  : 'Agregar'
              "
              tPosition="top"
              :icon="
                id
                  ? medical_revision
                    ? 'mdi-medical-bag'
                    : 'mdi-pencil'
                  : 'mdi-plus'
              "
              :color="
                id ? (medical_revision ? 'pink darken-2' : 'info') : 'success'
              "
              size="small"
              :click="formSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- DIALOGS -->
    <v-dialog v-model="insured_dialog" scrollable persistent max-width="1000">
      <v-card
        tile
        :loading="insured_dialog_loading"
        :disabled="insured_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Asegurados encontrados'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <td v-text="'ID'" />
                      <td v-text="'Asegurado'" />
                      <!-- <td v-text="'Vigencia'" /> -->
                      <td v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in insureds" :key="i">
                      <td v-text="v.enrollment" />
                      <td
                        v-text="
                          `${v.name} ${v.first_surname} ${v.second_surname}`
                        "
                      />
                      <td width="50px">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="warning"
                              @click="insuredData(v.id)"
                            >
                              <v-icon v-text="'mdi-check'" />
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>

            <v-col cols="12">
              <v-alert type="warning" dense>
                <v-row align="center">
                  <v-col class="text-center">
                    Asegurado no encontrado en sistema, ¿Desea registrar
                    manualmente la información?
                  </v-col>
                  <v-col class="shrink">
                    <v-btn
                      small
                      v-text="'Aceptar'"
                      @click.prevent="insuredForm"
                    />
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="services_dialog" scrollable persistent max-width="1800">
      <v-card
        tile
        :loading="services_dialog_loading"
        :disabled="services_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `${
                service_search.rs_service_type_id == 1
                  ? 'Médicos'
                  : 'Proveedores'
              } encontrados`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              v-if="
                data.rs_type_id != 4 &&
                data.rs_type_id != 5 &&
                login.role_id != 7 &&
                login.role_id != 8
              "
              color="warning"
              dark
              @click="serviceData(null)"
            >
              SE REQUIERE CONVENIO
            </v-btn>
            <v-btn icon dark @click="services_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-simple-table dense v-if="services.length > 0">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th
                        v-text="
                          'ID' +
                          (service_search.rs_service_type_id == 1 ? 'M' : 'P')
                        "
                      />
                      <th v-text="'Nombre'" />
                      <th v-text="'Estado'" />
                      <th v-text="'Municipio'" />
                      <th v-text="'Dirección'" />
                      <th v-text="'C.P.'" />
                      <th
                        v-if="
                          service_search.rs_service_type_id == 1 &&
                          login.role_id != 7 &&
                          login.role_id != 8
                        "
                        v-text="'Monto'"
                      />
                      <th
                        v-if="service_search.rs_service_type_id == 1"
                        v-text="'Confirm. Cita'"
                      />
                      <th
                        v-if="service_search.rs_service_type_id == 1"
                        v-text="'A. A. Médicamentos'"
                      />
                      <th
                        v-if="service_search.rs_service_type_id == 1"
                        v-text="'Pend. cobro'"
                      />
                      <th
                        v-if="service_search.rs_service_type_id == 1"
                        v-text="'Pend. pago'"
                      />
                      <th v-text="''" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in services" :key="i">
                      <td class="font-weight-bold" v-text="i + 1" />
                      <td>
                        {{ v.id }}
                        <span v-if="service_search.rs_service_type_id == 1">
                          <v-icon
                            :color="
                              v.valid != null
                                ? v.valid
                                  ? 'success'
                                  : 'error'
                                : ''
                            "
                          >
                            mdi-{{
                              v.valid != null
                                ? v.valid
                                  ? "check-"
                                  : "alert-"
                                : ""
                            }}decagram
                          </v-icon>
                        </span>
                      </td>
                      <td>
                        {{ v.name }}
                        <v-icon
                          v-if="v.auto_letter_generated"
                          small
                          right
                          color="success"
                        >
                          mdi-window-shutter-auto
                        </v-icon>
                      </td>
                      <td v-text="v.entity_name" />
                      <td v-text="v.municipality_name" />
                      <td>
                        {{
                          (v.street ? v.street : "") +
                          " " +
                          (v.external_number ? v.external_number : "") +
                          (v.internal_number
                            ? " INT. " + v.internal_number
                            : "") +
                          (v.suburb ? ", COL. " + v.suburb : "")
                        }}
                      </td>
                      <td v-text="v.zip" />
                      <td
                        v-if="
                          service_search.rs_service_type_id == 1 &&
                          login.role_id != 7 &&
                          login.role_id != 8
                        "
                        v-text="numberFormat(v.amount)"
                      />
                      <td v-if="service_search.rs_service_type_id == 1">
                        <v-icon
                          small
                          :color="v.confirm_consultation ? 'success' : ''"
                        >
                          mdi-{{ v.confirm_consultation ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-if="service_search.rs_service_type_id == 1">
                        <v-icon
                          small
                          :color="v.medication_auth ? 'success' : ''"
                        >
                          mdi-{{ v.medication_auth ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-if="service_search.rs_service_type_id == 1">
                        <div class="text-center">
                          <div v-if="v.rs_services_pending.length > 0">
                            {{ v.rs_services_pending.length }}
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  text
                                  small
                                  color="warning"
                                  @click="
                                    rsServicesPendingDlg(
                                      v.rs_services_pending,
                                      1,
                                      v.id,
                                      v.name
                                    )
                                  "
                                >
                                  <v-icon small> mdi-file-eye </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Ver registros'" />
                            </v-tooltip>
                          </div>
                          <span v-else v-text="'-'" />
                        </div>
                      </td>
                      <td v-if="service_search.rs_service_type_id == 1">
                        <div class="text-center">
                          <div v-if="v.rs_services_pending_paid.length > 0">
                            {{ v.rs_services_pending_paid.length }}
                            <v-tooltip left>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  text
                                  small
                                  color="pink"
                                  dark
                                  @click="
                                    rsServicesPendingDlg(
                                      v.rs_services_pending_paid,
                                      2,
                                      v.id,
                                      v.name
                                    )
                                  "
                                >
                                  <v-icon small> mdi-file-eye </v-icon>
                                </v-btn>
                              </template>
                              <span v-text="'Ver registros'" />
                            </v-tooltip>
                          </div>
                          <span v-else v-text="'-'" />
                        </div>
                      </td>
                      <td width="50px">
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              color="success"
                              @click="serviceData(v)"
                            >
                              <v-icon small v-text="'mdi-send'" />
                            </v-btn>
                          </template>
                          <span v-text="'Seleccionar'" />
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <span v-else>
                {{
                  services_dialog_loading
                    ? "cargando..."
                    : "No se encontraron registros conincidentes con la busqueda"
                }}
              </span>
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="
                  service_search.rs_service_type_id == 1 && data.rs_type_id == 4
                "
                color="warning"
                x-small
                dark
                @click="doctorDialog()"
              >
                <v-icon left> mdi-plus </v-icon>
                Agregar Médico
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="benefits_dialog" scrollable persistent max-width="1000">
      <v-card
        tile
        :loading="benefits_dialog_loading"
        :disabled="benefits_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="
              `Prestaciones encontradas ${
                benefits_list && benefits_list.length > 0
                  ? `(${benefits_list.length})`
                  : ''
              }`
            "
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="benefits_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table
            dense
            v-if="benefits_list && benefits_list.length > 0"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-text="'#'" />
                  <th v-text="'Código'" />
                  <th v-text="'Descripción'" />
                  <th v-text="'Cobertura'" />
                  <th v-text="'Monto'" />
                  <th v-text="''" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, i) in benefits_list" :key="i">
                  <td class="font-weight-bold" v-text="i + 1" />
                  <td v-text="v.code" />
                  <td v-text="v.description" />
                  <td
                    :class="v.revision ? 'orange--text text--darken-3' : ''"
                    v-text="v.coverage_type ? v.coverage_type : 'N/A'"
                  />
                  <td v-text="numberFormat(v.amount)" />
                  <td width="50px">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          icon
                          small
                          color="warning"
                          @click="benefitAdd(v)"
                        >
                          <v-icon v-text="'mdi-plus'" />
                        </v-btn>
                      </template>
                      <span v-text="'Seleccionar'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>
            {{
              benefits_dialog_loading
                ? "cargando..."
                : "No se encontraron registros conincidentes con la busqueda"
            }}
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rss_coinsidents_found_show"
      scrollable
      persistent
      max-width="1200"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Solicitudes de servicio coincidentes'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rss_coinsidents_found_show = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <td v-text="'Folio'" />
                  <td v-text="'Fecha'" />
                  <td v-text="'ID SM / Póliza'" />
                  <td v-text="'Aseguradora'" />
                  <td v-text="'Asegurado'" />
                  <td v-text="'Contratante'" />
                  <td v-text="''" />
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rss_found, i) in rss_coinsidents_found" :key="i">
                  <td v-text="rss_found.folio" />
                  <td v-text="rss_found.created_at" />
                  <td v-text="rss_found.enrollment" />
                  <td v-text="rss_found.insurance_name" />
                  <td v-text="rss_found.insured_full_name" />
                  <td v-text="rss_found.contractor" />
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          :to="{
                            name: `${page_route}.detalle`,
                            params: { id: rss_found.id },
                          }"
                          v-on="on"
                          x-small
                          dark
                          fab
                        >
                          <v-icon v-text="'mdi-file-eye'" />
                        </v-btn>
                      </template>
                      <span v-text="'Detalle'" />
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <br />
          <v-row justify="end">
            <v-btn depressed color="primary" @click="insuredSearch">
              Continuar con el registro
            </v-btn>
          </v-row> -->
        </v-card-text>
        <v-divider />
      </v-card>
    </v-dialog>

    <v-dialog v-model="doctor_dialog" scrollable persistent max-width="800">
      <v-card
        tile
        :disabled="doctor_dialog_loading"
        :loading="doctor_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> Agregar médico </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctor_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DATOS'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="form_doctor_data"
                    lazy-validation
                  >
                    <v-row v-if="doctor_data" dense>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="Nombre*"
                          v-model="doctor_data.name"
                          type="text"
                          maxlength="50"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Paterno*"
                          v-model="doctor_data.first_surname"
                          type="text"
                          maxlength="25"
                          dense
                          counter
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="4">
                        <v-text-field
                          label="A. Materno"
                          v-model="doctor_data.second_surname"
                          type="text"
                          maxlength="25"
                          dense
                          counter
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="RFC"
                          v-model="doctor_data.code_tax"
                          type="text"
                          maxlength="13"
                          dense
                          counter
                          :rules="rules.code_tax_sat_opt"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Consultorio | Hospital*"
                          v-model="doctor_data.provider_id"
                          :items="providers"
                          item-value="id"
                          :item-text="(v) => v.trade_name"
                          :loading="providers_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          label="Especialidad*"
                          v-model="doctor_data.specialty_type_id"
                          :items="specialty_types"
                          item-value="id"
                          :item-text="(v) => v.specialty_type"
                          :loading="specialty_types_loading"
                          dense
                          :rules="rules.required"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          label="Cédula"
                          v-model="doctor_data.license"
                          type="text"
                          maxlength="15"
                          dense
                          counter
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12">
                        <v-btn
                          block
                          color="success"
                          @click.prevent="doctorDialogSubmit"
                          small
                        >
                          ACEPTAR
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="insured_history_dialog"
      scrollable
      persistent
      max-width="900"
    >
      <v-card
        tile
        :disabled="insured_history_dialog_loading"
        :loading="insured_history_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> HISTORIAL MÉDICO </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_history_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insured_history_data">
          <v-row>
            <v-col cols="12" />
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          :color="
                            (insured_history_data.insured.medical_treatment
                              ? 'pink '
                              : 'grey') + ' darken-1'
                          "
                          dark
                          icon
                          @click.prevent="insuredMedicalTreatmentHandle"
                        >
                          <v-icon> mdi-shield-account </v-icon>
                        </v-btn>
                      </template>
                      <span
                        v-text="
                          (insured_history_data.insured.medical_treatment
                            ? 'Con'
                            : 'Sin') + ' tratamiento médico'
                        "
                      />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Nombre"
                        :value="
                          insured_history_data.insured.name +
                          ' ' +
                          insured_history_data.insured.first_surname +
                          ' ' +
                          insured_history_data.insured.second_surname
                        "
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="ID SM"
                        :value="insured_history_data.insured.enrollment"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Núm. asegurado"
                        :value="insured_history_data.insured.credential"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="F. nacimineto"
                        :value="insured_history_data.insured.birthday"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Vigencia"
                        :value="insured_history_data.insured.validity"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="4">
                      <ViewData
                        label="Contratante"
                        :value="insured_history_data.insured.contractor.name"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'DETALLE'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items />
                </v-toolbar>
                <v-card-text>
                  <v-row
                    v-for="(rs, i) in insured_history_data.rss"
                    :key="i"
                    dense
                  >
                    <v-col cols="12">
                      <v-spacer />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData
                        :label="
                          'Folio (' +
                          (insured_history_data.rss.length - i) +
                          ')'
                        "
                        :value="rs.folio"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="6">
                      <ViewData label="F. creación" :value="rs.created_at" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <ViewData label="Comentario" :value="rs.comment" />
                    </v-col>
                    <v-col cols="12" xs="12" md="12">
                      <v-row v-for="(icd, j) in rs.icds" :key="j" dense>
                        <v-col cols="2">
                          <ViewData
                            :label="'ICD (' + (j + 1) + ')'"
                            :value="icd.icd.code"
                          />
                        </v-col>
                        <v-col cols="10">
                          <ViewData label="Descripción" :value="icd.icd.icd" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dialog" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dialog = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="rs_services_pending_dlg"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title>
            PEND. {{ rs_services_pending_type == 1 ? "COBRO" : "PAGO" }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="rs_services_pending_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="rs_services_pending">
          <v-row class="pt-3">
            <v-col cols="12">
              <ViewData
                label="Médico"
                :value="
                  'IDM ' +
                  rs_services_pending_doctor_id +
                  ' | ' +
                  rs_services_pending_doctor_name
                "
              />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Folio'" />
                      <th v-text="'F. creación'" />
                      <th v-text="'F. carta'" />
                      <th v-text="'Monto asegurado'" />
                      <th v-text="'Doc. ID'" />
                      <th v-text="'Promesa de pago'" />
                      <th v-text="'Total a pagar'" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in rs_services_pending" :key="i">
                      <td
                        class="text-caption font-weight-bold"
                        v-text="item.key + 1"
                      />
                      <td>
                        <div class="text-center">
                          <span v-text="item.folio" />
                          <br />
                          <v-tooltip
                            v-if="login.permissions.rs_letters.read"
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                class="ml-1"
                                color="warning"
                                :to="{
                                  name: 'servicios.cartas',
                                  params: { id: item.rs_id },
                                }"
                              >
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                        </div>
                      </td>
                      <td v-text="item.created_at" />
                      <td v-text="item.letter_generated" />
                      <td v-text="numberFormat(item.assured_amount)" />
                      <td>
                        <div class="text-center">
                          <span v-text="item.doc_id" />
                          <br />
                          <v-tooltip
                            v-if="
                              item.rs_service_bill_id &&
                              login.permissions.rs_service_bills.read
                            "
                            left
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                icon
                                small
                                class="ml-1"
                                color="pink"
                                dark
                                :to="{
                                  name: 'facturacion_servicios.detalle',
                                  params: { id: item.rs_service_bill_id },
                                }"
                              >
                                <v-icon small> mdi-eye </v-icon>
                              </v-btn>
                            </template>
                            <span v-text="'Ver detalle'" />
                          </v-tooltip>
                        </div>
                      </td>
                      <td v-text="item.pay_date" />
                      <td
                        v-text="
                          item.pay_amount ? numberFormat(item.pay_amount) : ''
                        "
                      />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  dateTimeNow,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import { index, show, storeUpdate } from "../../requests/pageRequest";
import { catalog } from "../../requests/catalogRequest";
import FaqDlg from "../../components/FaqDlg.vue";
import RsServices from "../../components/RsServices.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    DatePicker,
    FaqDlg,
    RsServices,
  },
  data() {
    return {
      rules: rules(),
      page_route: "servicios",
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      show_data: false,
      loading: false,
      medical_revision: false,
      login: this.$store.getters.getLogin,
      data: {
        updated_by_id: this.$store.getters.getLogin.id,
        id: null,
        user_id: null,
        insurance_id: null,
        insured_id: null,
        insured: null,
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        office_address: "",
        contractor: "",
        email: "",
        phone: "",
        send_folio: "",
        detail: "",
        phone_medical: false,
        phone_medical_entity_id: null,
        phone_medical_municipality_id: null,
        accident: null,
        initial: null,
        preexisting: null,
        comment: "",
        services: [],
        icds: [],
        documents: [],
        rs_type_id: null,
        phone_medical_date: null,
        phone_medical_time: null,
      },
      users: [],
      users_loading: true,
      insurances: [],
      insurances_loading: true,
      entities: [],
      entities_loading: true,
      insured_search: {
        enrollment: "",
        name: "",
        first_surname: "",
        insurance_id: null,
      },
      insured_search_validation: true,
      insured_search_fail: false,
      insured_loading: false,
      insured_dialog: false,
      insured_dialog_loading: false,
      insured_form: false,
      insureds: [],
      rs_service_types: [],
      provider_types: [],
      provider_types_loading: true,
      municipalities: [],
      municipalities_loading: false,
      phone_medical_municipalities: [],
      phone_medical_municipalities_loading: false,
      service_search: {},
      services: [],
      services_dialog: false,
      services_dialog_loading: false,
      benefits_dialog: false,
      benefits_dialog_loading: false,
      service_index: null,
      benefits_list: null,
      icds: [],
      icds_loading: true,
      icd_loading: false,
      icd: null,
      letters_generated: false,
      rss_coinsidents_found_show: false,
      rss_coinsidents_found: [],
      rs_types: [],
      rs_types_loading: true,
      doctor_dialog: false,
      doctor_dialog_loading: false,
      doctor_data: null,
      specialty_types: [],
      specialty_types_loading: true,
      providers: [],
      providers_loading: true,
      insured_history_data: null,
      insured_history_dialog: false,
      insured_history_dialog_loading: false,
      sinister_rep: null,
      comment: null,
      comment_dialog: false,
      services_letter_generated: [],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      rs_services_pending: [],
      rs_services_pending_type: null,
      rs_services_pending_doctor_id: null,
      rs_services_pending_doctor_name: null,
      rs_services_pending_dlg: false,
    };
  },
  methods: {
    serviceSearchDefVal() {
      this.service_search = {
        rs_service_type_id: null,
        specialty_type_id: null,
        provider_type_id: null,
        entity_id: null,
        municipality_id: null,
        zip: "",
      };
    },
    fileAdd() {
      this.data.documents.push({
        id: null,
        active: 1,
        document_id: null,
        description: "",
        file: null,
      });
    },
    fileRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el documento (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.documents[i].id === null) {
              this.data.documents.splice(i, 1);
            } else {
              this.data.documents[i].active = false;
            }
          }
        });
    },
    userSelected() {
      if (this.show_data) {
        this.show_data = false;
      } else {
        this.data.insurance_id = null;
      }

      index(
        "user/" + this.data.user_id + "/insurances",
        this.login.token,
        []
      ).then((res) => {
        this.insurances = res.data;

        if (this.insurances.length == 1) {
          this.data.insurance_id = this.insurances[0].id;
        }

        this.insurances_loading = false;
      });
    },
    insuredSearchValidation() {
      this.insured_search_validation = true;
      this.insured_search_fail = false;

      this.insured_search.enrollment = this.insured_search.enrollment.trim();
      this.insured_search.name = this.insured_search.name.trim();
      this.insured_search.first_surname =
        this.insured_search.first_surname.trim();

      if (
        this.insured_search.enrollment.length > 1 &&
        this.insured_search.name.length > 2 &&
        this.insured_search.first_surname.length > 2
      ) {
        this.insuredCoincidenzeSearch();
      } else if (
        this.insured_search.enrollment.length > 1 &&
        this.insured_search.name.length === 0 &&
        this.insured_search.first_surname.length === 0
      ) {
        this.insuredCoincidenzeSearch();
      } else if (
        this.insured_search.enrollment.length === 0 &&
        this.insured_search.name.length > 2 &&
        this.insured_search.first_surname.length > 2
      ) {
        this.insuredCoincidenzeSearch();
      } else {
        this.insured_search_validation = false;
      }
    },
    insuredCoincidenzeSearch() {
      let search = [];
      search = [
        {
          name: "enrollment",
          value: this.insured_search.enrollment,
        },
        {
          name: "name",
          value: this.insured_search.name,
        },
        {
          name: "first_surname",
          value: this.insured_search.first_surname,
        },
        {
          name: "not_refound",
          value: 1,
        },
        {
          name: "operator",
          value: "=",
        },
      ];
      index("rss", this.login.token, search).then((res) => {
        this.rss_coinsidents_found = res.data;
        if (this.rss_coinsidents_found.length > 0) {
          this.rss_coinsidents_found_show = true;
        } else {
          this.insuredSearch();
        }
      });
    },
    insuredSearch() {
      this.rss_coinsidents_found_show = false;
      this.insured_loading = true;
      this.insured_search_fail = false;
      this.insured_search.insurance_id = this.data.insurance_id;

      Axios.post(
        URL_API + "/insureds/search",
        this.insured_search,
        headersToken(this.login.token)
      ).then((res) => {
        this.insureds = res.data.data;

        if (this.insureds.length === 0) {
          this.insured_search_fail = true;
        } else {
          if (this.insureds.length === 1) {
            this.insuredData(this.insureds[0].id);
          } else {
            this.insured_dialog = true;
          }
        }

        this.insured_loading = false;
      });
    },
    insuredData(id) {
      this.insured_dialog_loading = true;

      show("insureds", this.login.token, id).then((res) => {
        //console.log(res);
        this.data.insured = res;
        this.data.insured_id = this.data.insured.id;
        this.data.enrollment = this.data.insured.enrollment;
        this.data.name = this.data.insured.name;
        this.data.first_surname = this.data.insured.first_surname;
        this.data.second_surname = this.data.insured.second_surname;
        this.data.office_address = this.data.insured.office_address;
        this.data.contractor = this.data.insured.contractor.name;
        this.data.expired = this.data.insured.expired;
        this.data.validity = this.data.insured.validity;

        this.data.phone_medical_entity_id = this.data.insured.entity_id;
        this.data.phone_medical_municipality_id =
          this.data.insured.municipality_id;
        if (this.data.phone_medical_entity_id) {
          this.getMunicipalities(true);
        }

        this.serviceTypes();

        this.insured_dialog = false;
        this.insured_dialog_loading = false;
      });
    },
    serviceTypes() {
      this.provider_types_loading = true;
      this.provider_types = [];
      this.rs_service_types = [];

      index("provider/types", this.login.token, []).then((res) => {
        this.provider_types = res.data;

        if (this.data.insured_id != -1) {
          this.rs_service_types.push({
            id: 1,
            type: "CONSULTA",
            item_disabled:
              this.data.insured.contractor.consultation == 1 ? false : true,
          });

          index(
            "contractors/" + this.data.insured.contractor_id + "/tipos",
            this.login.token,
            []
          ).then((res) => {
            const contractor_provider_types = res.data;

            this.rs_service_types.push({
              id: 2,
              type: "PROVEEDOR",
              item_disabled:
                contractor_provider_types.length > 0 ? false : true,
            });

            for (let provider_type of this.provider_types) {
              let provider_type_exist = contractor_provider_types.filter(
                (i) => i.id == provider_type.id
              );

              provider_type.item_disabled =
                provider_type_exist.length === 0 ? true : false;
            }

            this.$forceUpdate();
          });
        } else {
          this.rs_service_types = [
            {
              id: 1,
              type: "CONSULTA",
              item_disabled: false,
            },
            {
              id: 2,
              type: "PROVEEDOR",
              item_disabled: false,
            },
          ];

          this.$forceUpdate();
        }

        this.provider_types_loading = false;
      });
    },
    insuredForm() {
      this.insured_dialog = false;
      this.data.insured_id = -1;
      this.data.enrollment = this.insured_search.enrollment;
      this.data.name = this.insured_search.name;
      this.data.first_surname = this.insured_search.first_surname;
      this.data.expired = false;

      this.serviceTypes();

      this.insured_form = true;
    },
    insuredRemove() {
      this.$swal
        .fire(
          msgConfirm(
            "Cuando se vuelve a buscar al asegurado la búsqueda de servicios y servicios serán reiniciados.¿Confirma continuar con esta acción?"
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.insured_form = false;

            this.data.insured_id = null;
            this.data.insured = null;
            this.data.enrollment = "";
            this.data.name = "";
            this.data.first_surname = "";
            this.data.second_surname = "";
            this.data.office_address = "";
            this.data.contractor = "";

            for (let i = 0; i < this.data.services.length; i++) {
              if (this.data.services[i].id !== null) {
                this.data.services[i].active = false;
              } else {
                this.data.services.splice(i, 1);
              }
            }

            this.serviceSearchDefVal();
          }
        });
    },
    getMunicipalities(phone_medical) {
      if (phone_medical) {
        this.phone_medical_municipalities_loading = true;
      } else {
        this.municipalities_loading = true;
      }

      index(
        "municipalities/" +
          (phone_medical
            ? this.data.phone_medical_entity_id
            : this.service_search.entity_id),
        this.login.token,
        []
      ).then((res) => {
        if (phone_medical) {
          this.phone_medical_municipalities = res.data;
          this.phone_medical_municipalities_loading = false;

          this.service_search.entity_id = this.data.phone_medical_entity_id;
          this.getMunicipalities(false);
        } else {
          this.municipalities = res.data;

          this.municipalities.push({
            id: null,
            municipality: "TODOS",
          });

          this.service_search.municipality_id =
            this.data.phone_medical_municipality_id;
          this.municipalities_loading = false;
        }
      });
    },
    serviceSearchDefTypes() {
      this.service_search.specialty_type_id = null;
      this.service_search.provider_type_id = null;
    },
    serviceSearch() {
      this.services = [];
      this.services_dialog = true;
      this.services_dialog_loading = true;

      index("service_search", this.login.token, [
        {
          name: "rs_service_type_id",
          value: this.service_search.rs_service_type_id,
        },
        {
          name: "specialty_type_id",
          value: this.service_search.specialty_type_id,
        },
        {
          name: "provider_type_id",
          value: this.service_search.provider_type_id,
        },
        {
          name: "entity_id",
          value: this.service_search.entity_id,
        },
        {
          name: "municipality_id",
          value: this.service_search.municipality_id,
        },
        {
          name: "zip",
          value: this.service_search.zip,
        },
        {
          name: "contractor_id",
          value: this.data.insured ? this.data.insured.contractor_id : null,
        },
      ]).then((res) => {
        this.services = res.data;

        if (this.login.role_id == 7 && this.services.length == 0) {
          this.services_dialog = false;
          this.serviceData(null);
        }
        this.services_dialog_loading = false;
      });
    },
    serviceDataAction(v, agreement) {
      let service_exist = false;
      let doctor_count = 0;

      if (!agreement) {
        service_exist = this.data.services.filter((i) =>
          this.service_search.rs_service_type_id == 1
            ? i.doctor_id == v.id
            : i.provider_id == v.id
        );

        service_exist = service_exist.length === 0 ? false : true;
      }

      console.log(this.data.services);

      for (const service of this.data.services) {
        if (service.doctor_id && service.active) {
          doctor_count++;
        }
      }

      if (
        !service_exist &&
        ((this.service_search.rs_service_type_id == 1 && doctor_count == 0) ||
          this.service_search.rs_service_type_id != 1)
      ) {
        this.serviceCreate(agreement, v);
      } else {
        // this.$swal
        //   .fire(
        //     msgConfirm("Este servicio ya ha sido agregado ¿Confirma agregarlo?")
        //   )
        //   .then((res) => {
        //     if (res.isConfirmed) {
        //       this.serviceCreate(agreement, v);
        //     }
        //   });
        if (service_exist) {
          this.$swal.fire(
            msgAlert("warning", "Este servicio ya ha sido agregado")
          );
        }

        if (doctor_count > 0) {
          this.$swal.fire(
            msgAlert(
              "warning",
              "Solo se puede agregar una consulta al servicio"
            )
          );
        }
      }
    },
    serviceData(v) {
      const agreement = v ? false : true;

      if (this.login.role_id == 7 && agreement) {
        this.serviceDataAction(v, agreement);
      } else {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma agregar ${agreement ? "CONVENIO para " : ""}${
                this.service_search.rs_service_type_id == 1
                  ? this.data.rs_type_id == 4 ||
                    this.data.rs_type_id == 5 ||
                    this.data.rs_type_id == 6
                    ? "MÉDICO"
                    : "CONSULTA"
                  : "PROVEEDOR"
              }?`
            )
          )
          .then((res) => {
            if (res.isConfirmed) {
              this.serviceDataAction(v, agreement);
            }
          });
      }
    },
    serviceCreate(agreement, v) {
      this.services_dialog_loading = true;

      let service = {
        id: null,
        active: true,
        rs_service_type_id: this.service_search.rs_service_type_id,
        specialty_type_id: this.service_search.specialty_type_id,
        specialty_type: {},
        provider_type_id: this.service_search.provider_type_id,
        provider_type: {},
        entity_id: this.service_search.entity_id,
        entity: {},
        municipality_id: this.service_search.municipality_id,
        municipality: {},
        zip: this.service_search.zip,
        doctor_id: null,
        doctor: null,
        consulting_room_id: null,
        consulting_room: null,
        doctor_specialty: null,
        provider_id: null,
        provider: null,
        agreement: agreement,
        confirm_consultation:
          v && v.confirm_consultation ? v.confirm_consultation : 0,
        confirm_consultation_by_id: null,
        confirm_consultation_at: null,
        medical_revision: this.medical_revision,
        benefits: [],
        benefits_amount: 0,
        benefit_search: {
          code: "",
          description: "",
        },
        assured_amount: null,
        auto_letter_generated: !agreement ? v.auto_letter_generated : false,
      };

      if (
        this.data.insured_id &&
        this.data.insured_id != -1 &&
        service.provider_type_id
      ) {
        service.assured_amount = parseFloat(
          this.provider_types.find((e) => e.id === service.provider_type_id)
            .assured_amount
        );
      }

      if (!agreement) {
        if (service.rs_service_type_id === 1) {
          service.doctor_id = v.id;
          service.consulting_room_id = v.consulting_room_id;
          this.doctorData(service);
        } else {
          service.provider_id = v.id;
          this.providerData(service);
        }
      } else {
        service.specialty_type = this.specialty_types.find(
          (e) => e.id === service.specialty_type_id
        );
        service.provider_type = this.provider_types.find(
          (e) => e.id === service.provider_type_id
        );
        service.entity = this.entities.find((e) => e.id === service.entity_id);
        service.municipality = this.municipalities.find(
          (e) => e.id === service.municipality_id
        );

        this.serviceAdd(service);
      }
    },
    doctorData(service) {
      show("doctors", this.login.token, service.doctor_id).then((res) => {
        service.doctor = res;

        service.consulting_room = service.doctor.consulting_rooms.filter(
          (i) => i.id === service.consulting_room_id
        );
        service.consulting_room = service.consulting_room[0];

        service.doctor_specialty = service.doctor.specialties.filter(
          (i) => i.specialty_type_id === service.specialty_type_id
        );
        service.doctor_specialty = service.doctor_specialty[0];

        service.benefits.push({
          id: null,
          code: null,
          description:
            `${service.doctor.name} ${service.doctor.first_surname} ${service.doctor.second_surname}`.trim(),
          coverage_type: null,
          revision: false,
          revision_by_id: null,
          revision_at: null,
          amount: service.doctor.consultation_amount,
          amount_original: service.doctor.consultation_amount,
          active: true,
          benefit_detail_id: null,
          coverage_type_id: null,
        });

        this.serviceAdd(service);
      });
    },
    providerData(service) {
      show("providers", this.login.token, service.provider_id).then((res) => {
        service.provider = res;
        this.serviceAdd(service);
      });
    },
    serviceAdd(service) {
      this.data.services.push(service);
      this.services_dialog = false;
      this.services_dialog_loading = false;
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    serviceRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar servicio (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.services[i].id !== null) {
              this.data.services[i].active = false;
            } else {
              this.data.services.splice(i, 1);
            }
          }
        });
    },
    benefitSearch(i) {
      this.data.services[i].benefit_search.code =
        this.data.services[i].benefit_search.code.trim();
      this.data.services[i].benefit_search.description =
        this.data.services[i].benefit_search.description.trim();

      if (
        this.data.services[i].benefit_search.code.length > 1 ||
        this.data.services[i].benefit_search.description.length > 1
      ) {
        this.benefits_list = null;
        this.benefits_dialog = true;
        this.benefits_dialog_loading = true;

        index("service/benefits", this.login.token, [
          {
            name: "provider_id",
            value: this.data.services[i].provider_id,
          },
          {
            name: "insurance_id",
            value: this.data.insurance_id,
          },
          {
            name: "contractor_id",
            value: this.data.insured ? this.data.insured.contractor_id : null,
          },
          {
            name: "code",
            value: this.data.services[i].benefit_search.code,
          },
          {
            name: "description",
            value: this.data.services[i].benefit_search.description,
          },
        ]).then((res) => {
          this.benefits_list = res.data;

          this.service_index = i;
          this.benefits_dialog_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo con mínimo 2 caracteres para la búsqueda de prestaciones"
          )
        );
      }
    },
    benefitAdd(item) {
      const benefit_exist = this.data.services[
        this.service_index
      ].benefits.filter(
        (v) => v.benefit_detail_id === item.benefit_detail_id && v.active
      );

      if (benefit_exist.length === 0) {
        this.data.services[this.service_index].benefits.push({
          id: null,
          code: item.code,
          description: item.description,
          coverage_type: item.coverage_type,
          revision: item.revision === 1 ? true : false,
          amount: item.amount,
          amount_original: item.amount_original,
          active: true,
          benefit_detail_id: item.benefit_detail_id,
          coverage_type_id: item.coverage_type_id,
        });

        this.benefitsTotalAmount(this.service_index);
        this.benefits_dialog = false;
      } else {
        this.$swal.fire(
          msgAlert("warning", "Esta prestación ya ha sido agregada")
        );
      }
    },
    benefitsTotalAmount(service_index) {
      this.data.services[service_index].benefits_amount = 0;

      for (let v of this.data.services[service_index].benefits) {
        if (v.active) {
          this.data.services[service_index].benefits_amount += parseFloat(
            v.amount
          );
        }
      }
    },
    benefitRemove(i, service_index) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar prestación (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.services[service_index].benefits[i].id !== null) {
              this.data.services[service_index].benefits[i].active = false;
            } else {
              this.data.services[service_index].benefits.splice(i, 1);
            }

            this.benefitsTotalAmount(service_index);
          }
        });
    },
    formSubmit() {
      let icd_exist = true;
      if (this.medical_revision && this.data.icds.length === 0) {
        icd_exist = false;
      }

      if (icd_exist && (this.data.email !== "" || this.data.phone !== "")) {
        if (this.$refs.form_submit.validate()) {
          this.$swal
            .fire(
              msgConfirm(
                `¿Confirma ${
                  this.id
                    ? this.medical_revision
                      ? "el seguimiento médico"
                      : "la edición"
                    : "la creación"
                } del registro?`
              )
            )
            .then((res) => {
              if (res.isConfirmed) {
                this.loading = true;

                this.data.insured_id =
                  this.data.insured_id === -1 ? null : this.data.insured_id;

                if (this.medical_revision && this.data.refound) {
                  this.data.services.push({
                    id: null,
                    active: true,
                    rs_service_type_id: 3,
                    specialty_type_id: null,
                    provider_type_id: null,
                    entity_id: null,
                    municipality_id: null,
                    zip: "",
                    doctor_id: null,
                    consulting_room_id: null,
                    doctor_specialty: null,
                    provider_id: null,
                    agreement: false,
                    confirm_consultation: 0,
                    confirm_consultation_by_id: null,
                    confirm_consultation_at: null,
                    medical_revision: true,
                    benefits: [],
                    benefits_amount: 0,
                    assured_amount: null,
                    auto_letter_generated: 0,
                  });
                }

                storeUpdate(
                  "rss",
                  this.login.token,
                  this.id,
                  this.data,
                  this.login.id
                ).then((res) => {
                  this.$swal.fire(
                    msgAlert(res.success ? "success" : "error", res.message)
                  );
                  if (res.success) {
                    if (
                      this.id &&
                      !this.medical_revision &&
                      this.letters_generated &&
                      this.data.discharged === null &&
                      this.data.send_folio
                    ) {
                      Axios.post(
                        URL_API + "/rss/discharge",
                        {
                          rs_id: this.id,
                          discharged: dateTimeNow(),
                          updated_by_id: this.login.id,
                        },
                        headersToken(this.login.token)
                      ).then((res) => {
                        this.$swal.fire(
                          msgAlert(
                            res.data.success ? "success" : "error",
                            res.data.message
                          )
                        );

                        res.data.success
                          ? this.$router.push({
                              name: `${this.page_route}${
                                this.id ? ".detalle" : ""
                              }`,
                              params: {
                                id: this.id,
                              },
                            })
                          : console.log(res.data.message);
                      });
                    } else {
                      this.$router.push({
                        name: `${this.page_route}${this.id ? ".detalle" : ""}`,
                        params: {
                          id: this.id,
                        },
                      });
                    }
                  } else {
                    console.log(res.data.message);
                  }
                });
              }
            });
        } else {
          this.$swal.fire(
            msgAlert(
              "error",
              "Formulario invalido, revisa los detalles señalados"
            )
          );
        }
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            !icd_exist
              ? "Se debe de agregar mínimo un ICD"
              : "Se debe de registrar un Correo electrónico y/o Teléfono"
          )
        );
      }
    },
    async rsServiceLetterGenerated(rs_service_id) {
      let resp = await Axios.post(
        URL_API + "/rss/service/letter_generated",
        {
          rs_service_id: rs_service_id,
          detail: null,
        },
        headersToken(this.login.token)
      );

      if (!resp.data.success) {
        this.$swal.fire(msgAlert("error", resp.data.message));
        console.log(resp.data.message);
      }
    },
    icdAdd() {
      this.icd_loading = true;

      if (this.icd) {
        let icd_exists = false;

        for (let i = 0; i < this.data.icds.length; i++) {
          if (
            this.data.icds[i].icd_id == this.icd.id &&
            this.data.icds[i].active
          ) {
            icd_exists = true;
          }
        }

        if (!icd_exists) {
          this.data.icds.push({
            id: null,
            icd_id: this.icd.id,
            code: this.icd.code,
            icd: this.icd.icd,
            active: true,
          });

          if (this.data.icds.length == 1) {
            this.icds_loading = true;

            Axios.get(
              URL_API +
                "/insureds/sinister/report?insured_id=" +
                this.data.insured_id +
                "&icd_id=" +
                this.icd.id,
              headersToken(this.login.token)
            ).then((res) => {
              this.sinister_rep = res.data.data;
              this.icds_loading = false;
            });
          }
        } else {
          this.$swal.fire(
            msgAlert("warning", "El ICD seleccionado ya ha sido agregado")
          );
        }
      } else {
        this.$swal.fire(msgAlert("error", "Selecciona una descripción de ICD"));
      }

      this.icd_loading = false;
    },
    icdRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar ICD (${i + 1})?`))
        .then((res) => {
          if (res.isConfirmed) {
            if (this.data.icds[i].id !== null) {
              this.data.icds[i].active = false;
            } else {
              this.data.icds.splice(i, 1);
            }

            if (this.data.icds.length == 0) {
              this.sinister_rep = null;
            }
          }
        });
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dialog = true;
    },
    initForm() {
      this.userSelected();

      if (!this.data.insured_id) {
        this.data.insured_id = -1;
        this.insured_form = true;
      }

      this.serviceTypes();

      if (this.data.phone_medical) {
        this.getMunicipalities(true);

        this.service_search.municipality_id =
          this.data.phone_medical_municipality_id;
      }

      if (this.medical_revision) {
        this.data.accident =
          this.data.accident !== null
            ? this.data.accident === 1
              ? true
              : false
            : null;
        this.data.initial =
          this.data.initial !== null
            ? this.data.initial === 1
              ? true
              : false
            : null;
        this.data.preexisting =
          this.data.preexisting === null ? false : this.data.preexisting;

        // if (this.data.accident === null && this.data.initial === null) {
        //   for (let service of this.data.services) {
        //     service.id = null;
        //     service.medical_revision = true;

        //     for (let benefit of service.benefits) {
        //       benefit.id = null;
        //     }
        //   }
        // }
      }

      this.loading = false;
    },
    lettersGenerated(services) {
      let services_letter = 0;
      let services_active = 0;
      this.letters_generated = false;

      for (let service of services) {
        if (service.active) {
          services_active++;

          if (service.letter_generated) {
            services_letter++;
          }
        }
      }

      if (services_active !== 0 && services_letter === services_active) {
        this.letters_generated = true;
      }
    },
    handleType() {
      let index = this.rs_service_types.findIndex((v) => v.id === 1);

      if (index != -1) {
        if (
          this.data.rs_type_id == 4 ||
          this.data.rs_type_id == 5 ||
          this.data.rs_type_id == 6
        ) {
          this.rs_service_types[index].type = "MÉDICO";
        } else {
          this.rs_service_types[index].type = "CONSULTA";
        }
      }
    },
    doctorDialog() {
      this.doctor_data = {
        name: "",
        first_surname: "",
        second_surname: "",
        code_tax: "",
        license: "",
        specialty_type_id: this.service_search.specialty_type_id,
        provider_id: null,
        validate_code_tax: true,
      };
      //this.$refs.form_doctor_data.resetValidation();
      this.doctor_dialog_loading = false;
      this.doctor_dialog = true;
    },
    doctorDialogSubmit() {
      if (this.$refs.form_doctor_data.validate()) {
        this.doctor_dialog_loading = true;

        Axios.post(
          `${URL_API}/doctors/service/valid`,
          this.doctor_data,
          headersToken(this.login.token)
        ).then((resp) => {
          this.doctor_dialog_loading = false;

          this.$swal
            .fire(
              msgConfirm(
                `${
                  resp.data.success ? "" : resp.data.message + " "
                }¿Confirma agregar al médico`
              )
            )
            .then((resp) => {
              if (resp.isConfirmed) {
                this.doctor_dialog_loading = true;

                Axios.post(
                  `${URL_API}/doctors/service`,
                  this.doctor_data,
                  headersToken(this.login.token)
                ).then((resp) => {
                  this.$swal.fire(
                    msgAlert(
                      resp.data.success ? "success" : "error",
                      resp.data.message
                    )
                  );

                  if (resp.data.success) {
                    this.doctor_dialog = false;
                    this.serviceSearch();
                  } else {
                    console.log(resp.data.message);
                  }

                  this.doctor_dialog_loading = false;
                });
              }
            });
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    insuredHistoryDialog() {
      this.insured_history_data = null;
      this.insured_history_dialog_loading = true;
      this.insured_history_dialog = true;
      Axios.get(
        `${URL_API}/rss/${this.id}/insured/${this.data.insured_id}/history/icd`,
        headersToken(this.login.token)
      ).then((resp) => {
        this.insured_history_data = resp.data.data;
        this.insured_history_dialog_loading = false;
      });
    },
    insuredMedicalTreatmentHandle() {
      this.$swal
        .fire(
          msgConfirm(
            "¿Confirma " +
              (this.insured_history_data.insured.medical_treatment
                ? "quitar de"
                : "poner en") +
              " tratamiento al asegurado?"
          )
        )
        .then((resp) => {
          if (resp.isConfirmed) {
            this.insured_history_dialog_loading = true;

            Axios.post(
              URL_API + "/insureds/medical/treatment",
              {
                id: this.insured_history_data.insured.id,
              },
              headersToken(this.login.token)
            ).then((resp) => {
              this.$swal.fire(
                msgAlert(
                  resp.data.success ? "success" : "error",
                  resp.data.message
                )
              );

              if (resp.data.success) {
                this.insured_history_data.insured.medical_treatment =
                  resp.data.medical_treatment;
              } else {
                console.log(resp.data.message);
              }

              this.insured_history_dialog_loading = false;
            });
          }
        });
    },
    rsServicesPendingDlg(items, type, doctor_id, doctor_name) {
      this.rs_services_pending = items;
      this.rs_services_pending_type = type;
      this.rs_services_pending_doctor_id = doctor_id;
      this.rs_services_pending_doctor_name = doctor_name;
      this.rs_services_pending_dlg = true;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    let route_name = this.$route.name;
    route_name = route_name.split(".");
    route_name = route_name[1];

    if (route_name === "seguimiento") {
      this.medical_revision = true;
    }

    this.serviceSearchDefVal();

    if (this.login.role_id === 1) {
      index("general/users", this.login.token, []).then((res) => {
        this.users = res.data;
        this.users_loading = false;
      });
    } else {
      this.data.user_id = this.login.id;
      this.userSelected();
      this.$forceUpdate();
    }

    catalog("entities", this.login.token).then((res) => {
      this.entities = res;

      this.entities.push({
        id: null,
        entity: "TODOS",
      });

      this.service_search.entities = null;

      this.municipalities.push({
        id: null,
        municipality: "TODOS",
      });

      this.service_search.municipality_id = null;

      this.entities_loading = false;
    });

    // index("specialty/types", this.login.token, []).then((res) => {
    //   this.specialty_types = res.data;
    //   this.specialty_types_loading = false;
    // });

    catalog("icds", this.login.token).then((res) => {
      this.icds = res;
      this.icds_loading = false;
    });

    catalog("rs_types", this.login.token).then((res) => {
      this.rs_types = res;
      if (this.login.role_id == 7) {
        this.rs_types = [
          { id: 1, type: "SERVICIOS", active: 1 },
          { id: 2, type: "REEMBOLSO", active: 1 },
        ];
      }
      this.data.rs_type_id = 1;
      this.rs_types_loading = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=specialty_types&no_order=false`,
      headersToken(this.login.token)
    ).then((response) => {
      this.specialty_types = response.data.data;
      this.specialty_types_loading = false;
    });
    Axios.get(
      `${URL_API}/providers/type/4`,
      headersToken(this.login.token)
    ).then((response) => {
      this.providers = response.data.data;
      this.providers_loading = false;
    });

    if (this.id) {
      this.loading = true;
      this.show_data = true;

      // Axios.get(
      //   URL_API + `/rss/${this.id}/${this.medical_revision ? 2 : 1}`,
      //   headersToken(this.login.token)
      // ).then((res) => {
      //   this.data = res.data.data;

      //   let services = [];

      //   if (this.data.accident !== null && this.data.initial !== null) {
      //     Axios.get(
      //       URL_API + `/rss/${this.id}/2`,
      //       headersToken(this.login.token)
      //     ).then((res) => {
      //       services = res.data.data.services;
      //       this.lettersGenerated(services);
      //     });
      //   } else {
      //     services = this.data.services;
      //     this.lettersGenerated(services);
      //   }

      // if (this.data.services.length === 0) {
      Axios.get(
        URL_API + `/rss/${this.id}/3`,
        headersToken(this.login.token)
      ).then((res) => {
        this.data = res.data.data;
        //console.log(this.data);

        // //remove services with letter_generated
        // let services_temp = [];
        // this.services_letter_generated = [];
        // for (const service of this.data.services) {
        //   if (!service.letter_generated) {
        //     services_temp.push(service);
        //   } else {
        //     this.services_letter_generated.push(service);
        //   }
        // }

        // this.data.services = services_temp;

        // if (this.data.insured_id) {
        //   Axios.get(
        //     `${URL_API}/rss/${this.id}/insured/${this.data.insured_id}/history/comments`,
        //     headersToken(this.login.token)
        //   ).then((resp) => {
        //     const comments = resp.data.data;

        //     let comment_temp = "";

        //     for (const comment of comments) {
        //       comment_temp +=
        //         "*** Folio " +
        //         comment.folio +
        //         " | " +
        //         comment.created_at +
        //         " ***\n" +
        //         comment.comment +
        //         "\n****************************\n";
        //     }

        //     this.data.comment = comment_temp;
        //   });
        // }

        this.initForm();
      });
      // } else {
      //   this.initForm();
      // }
      // });
    } else {
      if (this.login.role_id == 7) {
        this.loading = true;

        Axios.get(
          `${URL_API}/user/insured/${this.login.id}`,
          headersToken(this.login.token)
        ).then((response) => {
          //console.log(response.data.data);
          this.insuredData(response.data.data.id);
          this.data.email = this.login.email2;
          this.loading = false;
        });
      }
    }
  },
};
</script>